import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import bildteknikHeader from "../../images/bildteknik.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

const imageTechnologyPage = () => (
  <Main>
    <SEO title="Image Technology" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-PostIt" src={bildteknikHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">IMAGES IN SPACE</p>
          <p class="project-header-text-grey">IMAGE TECHNOLOGY</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Languages</p>
        <p class="project-bulletpoint-text"> &#9642; MATLAB</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-10-29</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          This project consists of two different mini-projects. The first
          project with the aim to combine images taken in space using{" "}
          <b>gamma correction</b>, representing different elements. One image
          could, for example, represent hydrogen as the colour green and another
          image could represent Oxygen as the colour blue. By combining the
          different images representing different elements, you get the
          resulting image of the captured object, for example, a nebula. This
          project idea came from the interest of how you take images in space,
          but due to a lack of material of such images representing elements, my
          associate and I had to complement this project with another.
          <br />
          <br />
          For the second project we wanted to stick to the already existing
          space theme, therefore we decided to measure the temperature in images
          of stars. This was accomplished with{" "}
          <b>Colour Corrected Temperature</b> to characterize light sources. By
          measuring the temperature of an image representing a star you can tell
          how old the star is.
          <br />
          <br />
          Except for all new knowledge about space, this project also thought us
          how to adapt our plans and restructure our work.
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects " href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default imageTechnologyPage
